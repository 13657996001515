import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

export const Tabmidpo = () => {
  return (
    <>
  <div className="bg-[#081226] xl:px-28 lg:px-16 md:px-12 sm:px-14 px-12  ">

<div className="container mx-auto xl:overflow-hidden lg:overflow-hidden md:overflow-hidden sm:overflow-hidden overflow-x-scroll xl:py-28">
  <table className="min-w-full bg-[#595B57] bg-opacity-40  rounded-xl ">
    <thead className="text-sm xl:text-3xl  lg:text-3xl md:text-3xl sm:text-xl font-sans font-bold ">
      <tr className=" text-[#79C942] ">
        <th className="py-5  border-b">Industries</th>
        <th className="py-5  border-b">Technologies</th>
        <th className="py-5  border-b">Platforms</th>
      </tr>
    </thead>
    <tbody className="text-xs xl:text-xl  lg:text-xl md:text-lg sm:text-base font-sans font-medium text-white">
      <tr className="  ">
        <td className="py-3 px-6">Financial Services</td>
        <td className="py-3 px-6">Blockchain</td>
        <td className="py-3 px-6">IOS & Android</td>
      </tr>
      <tr className="">
        <td className="py-3 px-6">Healthcare</td>
        <td className="py-3 px-6">IOT</td>
        <td className="py-3 px-6">Public, Private clouds</td>
      </tr>
      <tr className="">
        <td className="py-3 px-6">Retail</td>
        <td className="py-3 px-6">Web applications</td>
        <td className="py-3 px-6">Tools, Hadoop, Spark, Kafka</td>
      </tr>
      <tr className="">
        <td className="py-3 px-6">Automotive</td>
        <td className="py-3 px-6">Mobile Applications</td>
      
      </tr>
      <tr className="">
        <td className="py-3 px-6">Technology</td>
        <td className="py-3 px-6">Cloud Infrastructures</td>
      
      </tr>
      <tr className="">
        <td className="py-3 px-6">Government</td>
        <td className="py-3 px-6">On-Premise Infrastructures</td>
       
      </tr>
      <tr className="">
        <td className="py-3 px-6">Security</td>
        <td className="py-3 px-6">Databases</td>
      
      </tr>
      <tr className="">
        <td className="py-3 px-6">Marketplace Apps</td>
        <td className="py-3 px-6">Middlewares</td>
     
      </tr>
      <tr className="">
     
        <td className="py-3 px-6">ETL and Big Data pipelines</td>
       
      </tr>
      <tr className="">
    
        <td className="py-3  px-6">Kubernetes and Dockers</td>
      
      </tr>
  
    
    </tbody>
  </table>
</div>

</div>
    </>
  );
};
