import React from "react";
import profile1 from "../Images/others/profile1.png";
import profile2 from "../Images/others/profile2.png";
import profile3 from "../Images/others/profile3.png";
import {  FaAngleLeft } from "react-icons/fa";
import { MdOutlineStar } from "react-icons/md";
import { MdOutlineStarBorder } from "react-icons/md";

import { FaAngleRight } from "react-icons/fa";


export const Testimonial = () => {
  const Comp = [
    {
      icon: "",
      para: "  Lacking a security team, SIERRA Tech found Skandashield's pentesting service ideal. Their swift, efficient testing and clear report boosted our confidence, with implemented improvements already underway.",
      // img: profile1,
      name: "Malleswar",
      text:" Product Manager",
      text1:"SIERRA Tech",
      reviewStars:5
    },
    {
      icon: "",
      para: "E-commerce shop owner here! We prioritize security and Skandashield delivers. Their expert pentesters stay ahead of hacking trends, consistently exceeding our expectations. Trust them to safeguard your data!",
      // img: profile2,
      name: " Chas Henry",
      text:"VP",
      text1:"Bepainfreeglobal",
      reviewStars:5
    },
    {
      icon: "",
      para: "  Pre-Pentesting? Sleepless Nights! Now, thanks to pentesting Skandashield expert pentesting, we identified hidden risks, dodged a data breach disaster, and built rock-solid security. Their smooth collaboration and clear fixes let our customers rest easy.",
      // img: profile3,
      name: "Rayan",
      text:"",
      text1:"Bepainfreeglobal",
      reviewStars:5
    },
  
  ];
  return (
    <>
      <div className="bg-vector1 bg-cover bg-[#081226] xl:py-16 lg:py-12 md:py-10 sm:py-10 py-9 xl:h-full ">
        <h3 className=" text-xl xl:text-4xl  lg:text-4xl md:text-3xl sm:text-2xl font-sans font-bold text-center text-[#79C942]">
          TESTIMONIAL
        </h3>
      </div>
      <div className=" relative ">
        <div
          id="testimonial"
          className="testimonial  bg-[#081226] snap-mandatory snap-x  scroll  overflow-x-scroll flex xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col text-center xl:py-16 lg:py-10 md:py-16 py-16 sm:py-20 sm:gap-10 lg:gap-20 md:gap-10 xl:gap-20 gap-5 px-20 "
        >
          <button
            onClick={() => {
              document.getElementById("testimonial").scrollBy({ left: -100 });
            }}
            className="absolute  left-5 text-white xl:top-1/3 lg:top-1/3 md:top-1/3 sm:top-1/3 xl:block lg:block md:block sm:hidden hidden  bg-green-600 p-3 rounded-xl text-lg"
          >
            <FaAngleLeft />
          </button>
          {Comp.map((data, index) => (
            <div className="text-white snap-x snap-mandatory snap-center min-w-[34vw] xl:min-w-[26vw]  lg:min-w-[26vw] md:min-w-[26vw]  flex flex-col items-center border rounded-lg border-[#79C942] xl:p-8 lg:p-10 md:p-8 sm:p-5 p-5 glow-but">
   <div className="flex flex-row my-2 items-center justify-center">
                {Array.from({ length: data.reviewStars }).map(() => (
                  <MdOutlineStar size={30} color="yellow"/>
                ))}
                {Array.from({ length: 5- data.reviewStars  }).map(() => (
                  <MdOutlineStarBorder size={30}/>
                ))}
              </div>
              <p className="my-3   xl:text-base  sm:text-sm text-xs">{data.para}</p>
              <h3 className="text-xl xl:text-4xl  lg:text-4xl md:text-3xl sm:text-2xl   font-bold"><span className="bg-clip-text  text-transparent gradient-text-green "> {data.name}</span></h3>
              <p className="mb-2 font-bold  xl:text-base  sm:text-sm text-xs">{data.text}</p>
              <p className="mb-2 font-light  xl:text-base  sm:text-sm text-xs">{data.text1}</p>
            
             
              {/* <img src={data.img} alt="" className="mb-4 xl:h-20 lg:h-16 md:h-12 sm:h-10 h-8" /> */}
           
            </div>
          ))}
          <button
            onClick={() => {
              document.getElementById("testimonial").scrollBy({ left: 100 });
            }}
            className="absolute right-5 text-white xl:top-1/3 lg:top-1/3 md:top-1/3 sm:top-1/3 xl:block lg:block md:block sm:hidden hidden  bg-green-600 p-3 rounded-xl text-lg"
          >
                     <FaAngleRight />
          </button>
        </div>
      </div>
    </>
  );
};
