import React from "react";
import manage from "../Images/others/Group57.png";
import "aos/dist/aos.css";
import { FaPhoneAlt } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { RiMapPinRangeFill } from "react-icons/ri";

export const Contactop = () => {
  const Comp = [
    {
      heading: "India Office",
      para: "Have any questions? We'd love to hear from you!",
      phone: "+91 9962277200",
      mail: "contact@skandashield.com",
      address: "2nd Main Road, Gandhi Nagar, Adyar, Chennai.",
    },
    {
        heading: "United States Office",
      para: "Have any questions? We'd love to hear from you!",
      phone: "+1-650-488-9780",
      mail: "contact@skandashield.com",
      address: "18969 Ventura Blvd Box 317 Tarzana, CA 91356",
    },
  ];
  return (
    <>
      <div className="bg-[#081226] bg-light bg-cover items-center text-center  px-12 xl:px-16  lg:px-20  xl:py-32 lg:py-36 md:py-28 sm:py-32 sm:px-10 md:px-9  py-32 ">
        <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl ">
          Contact Us
        </h1>
      </div>

      <div className="bg-[#081226]  grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 py-10 lg:py-10 xl:py-20 md:py-16 sm:py-16 xl:px-10 lg:px-20 md:px-16 px-7 sm:px-20 lg:gap-5 md:gap-5  xl:gap-5 sm:gap-9 gap-5">
        {Comp.map((data) => (
          <>
            <div className=" border border-[#79C942] xl:p-8 lg:p-8 md:p-8 sm:p-6 p-6 rounded-xl text-white  flex flex-col  ">
              <h1 className="text-[#FBD131] tracking-wide font-bold xl:text-3xl lg:text-2xl md:text-xl sm:text-xl text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 mb-2">
               {data.heading}
              </h1>
              <p className="xl:text-xl lg:text-base md:text-base sm:text-base  text-sm mb-5 font-semibold">
              {data.para}
              </p>
              <div className="flex items-center mb-2">
              <FaPhoneAlt className="text-[#79C942] h-5 w-5" />
              <p className="xl:text-lg lg:text-base md:text-base sm:text-base  text-sm  ml-3" >
             {data.phone}
              </p>
              </div>
              <div className="flex items-center mb-2">

              <AiFillMail className="text-[#79C942] h-5 w-5"/>
              <p className="xl:text-lg lg:text-base md:text-base sm:text-base  text-sm ml-3">
              {data.mail}
              </p>
              </div>
              <div className="flex items-center mb-2">
              <RiMapPinRangeFill className="text-[#79C942] h-5 w-5"/>
              <p className="xl:text-lg lg:text-base md:text-base sm:text-base  text-sm ml-3">
              {data.address}
              </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};
