import React from 'react'

import { FaCircle } from "react-icons/fa";
import gif from '../Images/Product/circle.png'
import 'aos/dist/aos.css';



export const Empower = () => {
  return (
   <>
   
   <div className="bg-[#081226]  items-center grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16  lg:px-20  xl:py-8 lg:py-14 sm:px-10 md:py-10 sm:py-6 py-12 xl:gap-10 lg:gap-10 md:gap-10 sm:gap-6">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}
        <div data-aos="fade-right" data-aos-delay="300"     data-aos-duration="700" className="">
          <img
            src={gif}
            alt=""
            className="py-12 rounded-xl   "
          />
        </div>
        <div className=" ">
          <h1 className=" font-extrabold  text-[#79C942]  lg:text-4xl xl:text-5xl md:text-3xl sm:text-xl text-xl xl:mb-4 lg:mb-5 md:mb-3 sm:mb-3 mb-3 ">
          Empower Your Security Posture with Skandashield

          </h1>
          <h1 className='font-semibold text-white lg:text-xl xl:text-2xl md:text-xl sm:text-base text-sm xl:mb-4 lg:mb-5 md:mb-3 sm:mb-3 mb-3'> Continuous Protection, Unparalleled Coverage, Global Expertise</h1>

          <p className="  lg:text-base xl:text-lg md:text-base sm:text-xs text-xs  text-white leading-4">
          Partner with SkandaShield and experience the difference of continuous security. With our global team of experts and comprehensive platform, you can
          </p>


   
{/* 
          <button className=" lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
            Book Demo
          </button> */}
        </div>
      
      </div>
    
   
   
   </>
  )
}
