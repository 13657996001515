import React from "react";
import dec from "../Images/icons/dect.png";
import inv from "../Images/icons/inves.png";
import res from "../Images/icons/Responsive.png";
import imp from "../Images/icons/improve.png";
import dot from "../Images/Product/circle.png";

export const SaasappServices = () => {
 
  
  const Comp = [
    {
        title:'  Web Application Penetration Testing Services',
        para:"Find, validate, and patch vulnerabilities and improve the security of your online and complex applications even more."
      },
      {
        title:' Mobile Application Penetration Testing Services',
        para:"Find, validate, and fix vulnerabilities of your mobile application on android and IOS platforms"
      },
      {
        title:' SaaS Security Assessment',
        para:"SaaS pentesting powered by AI & expert eyes. Aligned with CIS, NIST, & more, we uncover deep vulnerabilities others miss"
      },
      {
        title:' Network Security Penetration Testing Services',
        para:"Add immediate value to your team’s work with web app scans benchmarking against OWASP Top 10 and improving your security hygiene."
      },
      {
        title:'Cloud Cyber Security Services Pentesting',
        para:"Find, validate, and fix vulnerabilities on your AWS, Azure, Google, Oracle and other cloud infrastructures."
      },
      {
        title:'  IoT Devices Penetration Testing Services',
        para:"Find, validate, and fix vulnerabilities on endpoints, cloud infrastructures, networks and applications including data steaming across platforms"
      },
      {
        title:'AI/ML Penetration Testing Services',
        para:"Embrace AI securely. Let our AI/ML pentesting expertise guide you. Build trust, mitigate risks, and unlock the full potential of your models"
      },
      {
        title:'  Secure Code Review',
        para:"Find application security vulnerabilities earlier in your SDLC with SAST, SCR, triaging, and remediation validation"
      },
      {
        title:' Strategic Advisory',
        para:"We help to build a mature security program starting with Application Security, program assessments, and threat modeling."
      },
    
  
     
    ]
  return (
    <>
        <div className=" bg-[#081226] xl:py-10  lg:py-10 md:py-8 sm:py-6 py-5 ">
        <h3 className=" text-xl xl:text-5xl  lg:text-4xl md:text-3xl sm:text-xl  font-sans font-bold text-center   text-[#79C942]">
        <span class="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">
        So what exactly do we do?</span>
        </h3>
        <p className=" font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs mt-3 px-12 text-white">
        Uncover hidden vulnerabilities before attackers do. Penetration testing strengthens your defenses and protects your business from costly data breaches.
        </p>
      </div>

        <div className="bg-[#081226] xl:gap-10 lg:gap-10 md:gap-10 grid  xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 sm:gap-5 gap-6 xl:py-28 md:py-24 sm:py-16 py-10 lg:py-24 xl:px-10 lg:px-10 md:px-11 sm:px-10 px-16 ">
            {Comp.map((data) => (
                <>
                <div className=' text-white border-2  hover:bg-[#3C7317] duration-200 border-[#3C7317]  rounded-2xl flex flex-col  xl:p-10 lg:p-10 md:p-8 sm:p-8 p-5 ' >
                <h1 className='font-bold xl:text-2xl hover:text-[#FBD131] lg:text-2xl md:text-xl sm:text-base text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942]'>{data.title}</h1>
                <p className='xl:text-xl lg:text-xl md:text-lg sm:text-xs text-xs'>{data.para}</p>
               

                </div>

                </>
        ))}
        </div>
    </>
  );
};
