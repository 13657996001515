import React from 'react'
import rec1 from '../Images/Product/rec3.png'
import rec2 from '../Images/Product/rec2.png'
import rec3 from '../Images/Product/rec1.png'

export const CoroProduct = () => {


    const Comp = [
        {
            img:rec1,
            title1:'Intuitive Interface',
            title2:'',
            para:'One dashboard, total picture. View, analyze, and act on statuses, events, and logs across all modules, instantly.',
           
          },
          {
            img:rec2,
            title1:'Agent Management',
            title2:'',
            para:"One agent, total control: Manage all your endpoint security needs effortlessly with SkandaShield's comprehensive, conflict-free solution.",
           
          },
          {
            img:rec3,
            title1:'Data Management',
            title2:'',
            para:'Synergy, not silos: Shared data fuels smart modules, strengthening your security without integration headaches',
           
          },
   
        ]
  return (
    <>
    
    <div className=" bg-[#081226] xl:py-10 xl:px-36 lg:px-14 md:px-10 lg:py-12 md:py-8 sm:py-8 py-8">
       
        <h1 className='text-[#79C942] xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-2xl font-sans font-bold text-center mb-6 xl:px-8 lg:px-16 md:px-6 sm:px-6 px-1 '>
        <span class="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">
        What Makes SkandaShield XDR Platform Different? </span>  </h1>
        <h1 className='text-white xl:text-4xl lg:text-4xl md:text-xl sm:text-xl font-sans font-bold text-center mb-6 xl:px-14 lg:px-24 md:px-10 sm:px-10 px-9 '>
        Unleash the power of simplicity that gives you Total Security Visibility</h1>


        <p className=" font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg  md:text-sm sm:text-xs xl:px-16 lg:px-24 md:px-10 sm:px-10 px-6   text-white ">
        Unify your security vision. Our intuitive interface empowers you to monitor, manage, & conquer threats with real-time insights
        </p>
      </div>

      <div className="bg-[#081226] grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 md:py-20 lg:py-20 sm:py-10 py-10 xl:py-20 sm:gap-16 md:gap-6 xl:gap-2 lg:gap-10 gap-14 ">
            {Comp.map((data) => (
                <>
                <div className=' text-white text-center flex items-center flex-col  xl:px-16 lg:px-14 md:px-10 sm:px-10 px-10 ' >

                <img
          src={data.img}
          alt=""
          className="  xl:px-20 lg:px-8 md:px-2 sm:px-52 px-12  justify-center "
        />
                <h1 className='font-bold xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-xl  mt-8 mb-4 text-[#79C942]'>{data.title1}</h1>
                {/* <h3 className='font-bold xl:text-lg lg:text-lg xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2'>{data.title2}</h3> */}
                <p className=''>{data.para}</p>
               

                </div>

                </>
        ))}
        </div>
      
    </>
  )
}
