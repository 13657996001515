// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMB3FuGzVqnRS5dMDQ-9_H4wYREweGiI4",
    authDomain: "skandashield-bc533.firebaseapp.com",
    projectId: "skandashield-bc533",
    storageBucket: "skandashield-bc533.appspot.com",
    messagingSenderId: "745608858110",
    appId: "1:745608858110:web:d8de86e02b76eec6f7d8af",
    measurementId: "G-4C91N67855"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };