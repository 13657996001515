import React from 'react'

export const Ourpenera = () => {


    const Comp = [
        {
            title:'Еliminate security blind spots',
            para:"Scan all of your web applications – JavaScript, AJAX, HTML5, Multi-Page and Single-Page Applications, and APIs."
          },
          {
            title:'Focus on great outputs',
            para:"Automate your vulnerability testing while saving time and focusing on developing secure web apps and APIs. Embed security in the development process"
          },
          {
            title:'Enhanced scanning confidence',
            para:"Implement a vulnerability scanner in your build that you can trust – very low false positives and negatives. Get accurate reports with remediation advice."
          },
          {
            title:'Fast security assessment',
            para:"Add immediate value to your team’s work with web app scans benchmarking against OWASP Top 10 and improving your security hygiene."
          },
          {
            title:'Reduce the risk of being hacked',
            para:"spot security risk before hackers do. Prevent modern attacks and possible exploited security issues."
          },
          {
            title:'Actionable vulnerability reports',
            para:"Download useful scan reports in PDF, JSON/XML, and CSV and easily share them with team members, executives, and clients."
          },
         
        ]
  return (
   <>
       <div className="bg-vectorsolution bg-[#081226]   ">
        <h3 className="font-bold  text-center text-white xl:text-3xl md:text-xl lg:text-3xl xl:py-24 py-10 md:py-20 lg:py-28 sm:py-10 xl:px-40 lg:px-52 px-10">
        
        Secure what matters: Powerful tools, expert eyes. Unleash top-notch pen testing.
        </h3>
        </div>

        <div className="bg-[#081226] xl:gap-10 lg:gap-10 md:gap-10 grid  xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 sm:gap-5 gap-6 xl:py-36 md:py-24 sm:py-16 py-10 lg:py-24 xl:px-10 lg:px-10 md:px-11 sm:px-10 px-16 ">
            {Comp.map((data) => (
                <>
                <div className=' text-white border-4 border-t-white border-[#3C7317]   flex flex-col  xl:p-10 lg:p-10 md:p-8 sm:p-3 p-3 ' >
                <h1 className='font-bold xl:text-2xl lg:text-2xl md:text-xl sm:text-base text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942]'>{data.title}</h1>
                <p className='xl:text-xl lg:text-xl md:text-lg sm:text-xs text-xs'>{data.para}</p>
               

                </div>

                </>
        ))}
        </div>
   
   
   </>
  )
}
