import React, { useState } from 'react';

export const GetinTouch = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    subscribe: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    // Clear the corresponding error when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      formIsValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone Number is required';
      formIsValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number format';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, you can submit it
      console.log(formData);
    } else {
      // Form is not valid, handle errors or display a message
      console.error('Form validation failed');
    }
  };

  return (
    <>
      <div className="bg-[#081226] xl:py-32 lg:py-36 md:py-28 sm:py-32 py-20">
        <form
          className="bg-[#081226] px-12 xl:px-96 lg:px-80 sm:px-40 md:px-52 xl:text-lg lg:text-base md:text-base sm:text-base text-sm"
          onSubmit={handleSubmit}
        >
          <label className="block mb-6 text-white ">
            Name
            <input
              className={`w-full p-2 border text-black rounded ${errors.name && 'border-red-500'}`}
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
          </label>

          <label className="block mb-6 text-white ">
            Email
            <input
              className={`w-full p-2 border rounded text-black ${errors.email && 'border-red-500'}`}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </label>

          <label className="block mb-6 text-white ">
            Phone Number
            <input
              className={`w-full p-2 border rounded text-black ${errors.phoneNumber && 'border-red-500'}`}
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>
            )}
          </label>

          <label className="block mb-6 text-white ">
            Message
            <textarea
              className="w-full p-2 border rounded text-black"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </label>

          <label className="block mb-6 text-white ">
            <input
              className="mr-2"
              type="checkbox"
              name="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
            />{' '}
            I'm not a robot
          </label>
          <div className="flex justify-center">
            <button
              className="lg:font-semibold text-center text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2 rounded-md text-white bg-[#3C7317] lg:mt-8 md:mt-4 sm:mt-4 mt-3"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
