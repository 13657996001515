import React from "react";
import HeroBackground from "./HeroBackground";
export const HeroToper = () => {
  return (
    <>
      <div className="bg-[#081226] relative  h-screen w-full overflow-hidden bg-cover items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16  lg:px-20  xl:py-16 lg:py-16 md:py-16 sm:py-10 sm:px-10 md:px-9  py-16 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}
        <HeroBackground />
        <div className="z-20 xl:text-left  lg:text-left md:text-left sm:text-left text-center xl:mt-20 lg:mt-20 md:mt-16 sm:mt-14 mt-9">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-10">
          SkandaShield: AI-Enabled Cybersecurity Platform, Proactive Insights, Lasting Protection
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white mb-10">
            Go beyond detection & response. AI-guided cyber platform predicts &
            stops attacks before they happen. Secure your future with SkandaShield Platform.
          </h1>
          <a href="demo">
            <button className="glow-button lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3 ">
              Book Demo
            </button>
          </a>
        </div>
        {/* <div className="">
          <img
            src={myImage}
            alt=""
            className=" hidden xl:block lg:block md:block sm:block  "
          />
        </div> */}
      </div>
    </>
  );
};
