import React from "react";
import { useState } from "react";

export const WebsiteScanners = () => {
  const [formData, setFormData] = useState({
    target: '',
    hostName: '',
    protocol: 'http',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your validation logic here
    console.log('Form submitted:', formData);
  };
 return (
    <>
      <div className="bg-[#081226] xl:gap-28 items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16 lg:px-20 xl:py-20 lg:py-20  sm:px-10 md:px-16 lg:gap-10 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className=" ">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-xl lg:mt-5 md:mt-3 ">
            Website Vulnerability Scanner
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
            The Website Vulnerability Scanner is a highly-accurate vulnerability
            scanning solution, battle-tested in real life penetration testing
            engagements.
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
            Quickly detect XSS, SQL injection, Command injection, XXE and other
            critical issues - automatically validated to eliminate false
            positives.
          </h1>

          <button className=" lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
            Book Demo
          </button>
        </div>

        <div className="xl:w-full p-4 bg-gray-100 rounded shadow">
      <form onSubmit={handleSubmit} className="">
      <h1 className=" font-bold  text-[#79C942] text-center text-2xl lg:text-2xl xl:text-2xl md:text-3xl sm:text-xl  xl:mb-5 lg:mb-5">
           Light Scan
          </h1>
        <div className="mb-4">
          <label htmlFor="target" className="block text-gray-700 font-bold mb-2">
            Target:
          </label>
          <input
            type="text"
            id="target"
            name="target"
            placeholder="https://example.com"
            value={formData.target}
            onChange={handleChange}
            required
            className="w-full p-2  rounded  border border-[#79C942]"
          />
        </div>
   
        <div className="mb-4 ">
          
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="protocol"
              value="http"
              checked={formData.protocol === 'http'}
              onChange={handleChange}
              className="form-radio h-5 w-5 text-blue-500"
            />
            <span className="ml-2 xl:text-sm">HTTP</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input
              type="radio"
              name="protocol"
              value="https"
              checked={formData.protocol === 'https'}
              onChange={handleChange}
              className="form-radio h-5 w-5 text-blue-500"
            />
            <span className="ml-2 xl:text-sm">HTTPS</span>
          </label>
        </div>
        <div className="text-center ">
        <button
          type="submit"
         className="lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 xl:px-5 xl:py-2 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3"
        >
          Start Scan
        </button>
        </div>
      </form>
    </div>


      </div>
    </>
  );
};
