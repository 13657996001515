import React from "react";
import 'aos/dist/aos.css';

import pre from "../Images/icons/material-symbols_laptop-mac.png";
import clo from "../Images/icons/bi_shield-lock-fill.png";
import hyp from "../Images/icons/fluent_document-dismiss-20-filled.png";
import gif from "../Images/Product/pro.png";

export const EnvironmentsServices = () => {
  const Comp = [
    {
      icon: pre,
      title: "1. Simplified Compliance",
      po1: "Using the Slack Channel, our skilled pentesters promptly test on a regular basis to verify full asset coverage and compliance with PCI, HIPAA, SOC-2, ISO 27001, GDPR, and other regulations.",
     
    },
    {
      icon: clo,
      title: "2. Shorter execution time",
      po1: "Within 24 hours, you will have your pentest setup. Integrate pentest results directly into your SDLC and work with our pentesters to expedite triage, remediation, and retesting processes.",
     
    },
    {
      icon: hyp,
      title: "3. On-Demand Reliable Expertise",
      po1: "Access a broad worldwide network of pentesters who have been thoroughly verified. Partner with a team that has the experience and abilities to complement your technology stack.",
    
    },
  ];
  return (
    <>
      <div className="bg-[#081226] ">
        <div className="bg-serv bg-cover  xl:py-10 xl:h-full lg:py-10 md:py-14 sm:py-10 py-10 ">
          <h1 className="text-[#79C942] xl:text-5xl lg:text-4xl md:text-2xl sm:text-2xl text-2xl font-sans font-bold text-center xl:mb-5  ">
          How are we different?
          </h1>

       
        </div>

 

        <div className="bg-[#081226] w-full grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 text-center xl:py-16 xl:px-32 md:px-10 px-16 xl:gap-28 md:py-12 lg:py-16 sm:py-16 py-12 lg:px-20 sm:px-44 lg:gap-20 md:gap-10 sm:gap-10 gap-10">
          {Comp.map((data, index) => (
            <div data-aos-delay="100"     data-aos-duration="700" data-aos="fade-down" className="text-white   flex  flex-col relative items-center border rounded-lg border-[#79C942] xl:p-8 lg:p-8 md:p-8 sm:p-10 p-8">
             
              <img src={data.icon} alt="" className="xl:mb-4 absolute xl:-left-7 xl:top-1/2 lg:-left-6 lg:top-1/2  md:-left-5 md:top-1/2  sm:-left-6 sm:top-1/2   -left-5 top-1/2    -translate-y-1/2 xl:h-14 lg:h-12 md:h-10 sm:h-12 h-10 " />

              <h1 className="text-[#FBD131] font-semibold mb-4 xl:text-2xl lg:text-2xl md:text-2xl sm:text-base text-base">{data.title}</h1>
              {/* <img src={data.img} alt="" className="xl:mb-4" /> */}
              <p className="font-semibold leading-loose xl:text-base lg:text-base md:text-base sm:text-xs text-xs ">{data.po1}</p>
           
            </div>
          ))}
        </div>

        <div data-aos="zoom-in" data-aos-delay="100"     data-aos-duration="500" className="xl:py-10  lg:py-5 md:py-8  py-2 xl:px-96  lg:px-56 md:px-20 sm:px-20 px-5 sm:py-3  flex justify-center">
          <img
            src={gif}
            alt=""
            className="py-12   "
          />
        </div>
   
      </div>
    </>
  );
};
