import React from 'react'
import scan from '../Images/icons/setscan.png'
import ver from '../Images/icons/verify.png'
import scbe from '../Images/icons/sche.png'

export const Projectmin = () => {
    const Comp = [
        {
            icon:scan,
            para:"Set the Scan Target"
          },
          {
            icon:ver,
            para:"Verify it"
          },
          {
            icon:scbe,
            para:"Scan and schedule"
          },
          
         
        ]
  return (
    <>
       <div className="bg-vectorsolution bg-[#081226] xl:py-10 xl:h-full xl:px-36  lg:py-20  md:py-16 sm:py-14 py-10">
       
       <h1 className='text-[#79C942] xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl font-sans font-bold text-center mb-5'>
       Set up your pentesting project in minutes</h1>

       <p className=" font-sans font-normal  text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs mb-6  text-white  ">
       Scan automatically and integrate pentesting into your workflow with ease.
       </p>
       <p className=" font-sans font-normal italic text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs mb-16 text-white xl:px-72 lg:px-20 md:px-20 sm:px-16">
       Our excellent support team of security consultants is one click away from helping you set up, verify and make sense of your scans.
       </p>
       <div className=" flex text-center xl:py-8 xl:px-10 px-5  justify-around ">
        {Comp.map((data, index) => (
          <>
            <div className="text-white flex flex-wrap flex-row items-center  ">
            <img src={data.icon} alt="" className="xl:h-12 mr-2 lg:h-14 md:h-12 sm:h-10 h-8"/>

              <h2 className=" xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl text-xs font-bold">{data.para}</h2>
            </div>
          
          </>
        ))}
      </div>
    
     </div>


    
    </>
  )
}
