import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavbarSka from "./Components/NavTop";
import { HeroToper } from "./Components/HeroTop";
import { VideoSection } from "./Components/Video";
import { MangSecurity } from "./Components/MangSec";
import { SecurityCenter } from "./Components/Security";
import { Testimonial } from "./Components/Test";
import { FooterTab } from "./Components/Footer";
import { Productherosec } from "./Product/Producthero";
import { CoroProduct } from "./Product/Coro";
import { CoroServices } from "./Services/Coro";
import { Saasapp } from "./Product/Saas";
import { SaasappServices } from "./Services/Saas";
import { Environments } from "./Product/Envi";
import { EnvironmentsServices } from "./Services/Envi";
import { TopHerosolutions } from "./Solution/TopHerosol";
import { Empower } from "./Solution/Empow";
import { Ourpenera } from "./Solution/Ourpe";
import { HowitSteps } from "./Solution/Howit";
import { Projectmin } from "./Solution/Project";
import { Penteresting } from "./Solution/Pent";
import { Formpoint } from "./Demo/Form";
import { WebsiteScanners } from "./Scan/Website";
import { NetworkScans } from "./Scan/Network";
import { Subdomains } from "./Scan/Subdo";
import { Blogmed } from "./Blog/Blogme";
import { Patvul } from "./Services/Producthero";
import { ImageCardRow } from "./Services/ImageCard";
import { useEffect, useState } from "react";
import { createClient } from "next-sanity";
import Blogs from "./Blog/Blogs";
import { AbouTops } from "./About/AbouTop";
import { Tabmidpo } from "./About/TaMid";
import { Twocard } from "./About/Twoca";
import { Footbott } from "./About/Footbo";
import { Contactop } from "./Contactus/Conto";
import { GetinTouch } from "./Contactus/Getin";
import HeroBackground from "./Components/HeroBackground";
import ScrollToTopButton from './Components/ScrollToTopButton';
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "./firebaseconfig";



export function signIn() {
  signInWithPopup(auth, provider)
    .then((data) => {
      localStorage.setItem("userEmail", data?.user?.email);
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
    });
}
function App() {
  const [blogs, setBlogs] = useState(null);

  const client = createClient({
    projectId: "fqwcc10o",
    dataset: "production",
    apiVersion: "2022-03-25",
  });

  async function fetchData() {
    const data = await client.fetch('*[_type ==  "post"]');
    console.log(data)
    setBlogs(data);
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <NavbarSka />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>

                <HeroToper />
                <VideoSection />

                <MangSecurity />
                <SecurityCenter />
                <Testimonial />
              </>
            }
          />

          <Route
            path="product"
            element={
              <>
                <Productherosec />
                <CoroProduct />
                <Saasapp />
                <Empower />
                <Environments />
              </>
            }
          />

          <Route
            path="services"
            element={
              <>
                <CoroServices />
                <SaasappServices />
                <EnvironmentsServices />
                <Patvul />
                <ImageCardRow />

              </>
            }
          />


          <Route
            path="aboutus"
            element={
              <>
                <AbouTops />
                <Tabmidpo />
                <Twocard />
                <Footbott />


              </>
            }
          />

          <Route
            path="contactus"
            element={
              <>
                <Contactop />
                <GetinTouch />



              </>
            }
          />

          {/* <Route
            path="solution"
            element={
              <>
                <TopHerosolutions />
               
                <Ourpenera />
                <HowitSteps />
                <Projectmin />
                <Penteresting />
              </>
            }
          /> */}

          <Route
            path="demo"
            element={
              <>
                <Formpoint />
              </>
            }
          />

          <Route
            path="website"
            element={
              <>
                <WebsiteScanners />
              </>
            }
          />


          <Route
            path="network"
            element={
              <>
                <NetworkScans />
              </>
            }
          />

          <Route
            path="subdomain"
            element={
              <>
                <Subdomains />
              </>
            }
          />

          <Route path="blog" element={blogs ? <Blogs blogs={blogs} /> : <div className="bg-[#081226] min-h-screen w-full"></div>} /> :
          {
            blogs ? blogs.map((blog) => (
              <Route path={`blog/${blog?.slug?.current}`} element={<Blogmed blog={blog} />} />
            ))
              : <Route path={`blog`} element={<div className="bg-[#081226] min-h-screen w-full"></div>} />}
        </Routes>
      </BrowserRouter>
      {
        !blogs &&
        <div className="bg-[#081226] min-h-screen w-full"></div>}
      <FooterTab />
      <ScrollToTopButton />
    </>
  );
}

export default App;
