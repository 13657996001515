import React from 'react'
import { useState } from 'react';




export const NetworkScans = () => {

  const [formData, setFormData] = useState({
    field1: '',
    field2: '',
    checkbox: false,
  });

  const [formErrors, setFormErrors] = useState({
    field1: '',
    field2: '',
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    let errors = {};
    if (formData.field1.trim() === '') {
      errors.field1 = 'Field 1 is required';
    }
    if (formData.field2.trim() === '') {
      errors.field2 = 'Field 2 is required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // If validation passes, you can proceed with form submission or other actions
    console.log('Form submitted:', formData);
  };
  return (
    <>
          <div className="bg-[#081226] xl:gap-28 items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16 lg:px-20 lg:py-28  xl:py-16  sm:px-10 md:px-16 lg:gap-10 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className="   lg:mt-11 md:mt-6 sm:mt-2">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-xl lg:mt-5 md:mt-3 ">
          Network
Vulnerability
Scanner
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
          Discover outdated network services, missing security patches, misconfigured servers, and many more critical vulnerabilities.
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
          Our scanner lets you run in-depth scans with proprietary detection to find Log4Shell, OMIGOD, ProxyShell and thousands more critical CVEs and security issues.
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
          Paid plans give you access to its full capabilities, plus other 20+ security testing tools and features.
          </h1>

          <button className=" lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
            Book Demo
          </button>
        </div>

        <div className=" p-6 bg-white shadow-md rounded-md ">
      <form onSubmit={handleSubmit}>
      <h1 className=" font-bold  text-[#79C942] text-center text-2xl lg:text-2xl xl:text-2xl md:text-3xl sm:text-xl  xl:mb-5 lg:mb-5">
           Light Scan
          </h1>

        <div className="mb-4">
          <label htmlFor="field1" className="block text-base font-medium text-gray-600">
          Target
          </label>
          <input
            type="text"
            id="field1"
            name="field1"
            placeholder="https://example.com"
            value={formData.field1}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-blue-500"
          />
          {formErrors.field1 && <div className="text-red-500 text-xs mt-1">{formErrors.field1}</div>}
        </div>

        <div className="mb-4">   <div className="mb-4">
          <label htmlFor="field1" className="block text-base font-medium text-gray-600">
          Port selection
          </label>
          <div className="mt-1">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="field2"
                value="option1"
                checked={formData.field2 === 'option1'}
                onChange={handleInputChange}
                className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue"
              />
              <span className="ml-2 xl:text-sm">Common ports</span>
            </label>
            <label className="inline-flex items-center ml-4">
              <input
                type="radio"
                name="field2"
                value="option2"
                checked={formData.field2 === 'option2'}
                onChange={handleInputChange}
                className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue"
              />
              <span className="ml-2 xl:text-sm">List of ports</span>
            </label>
          </div>
          <input
            type="text"
            id="field1"
            name="field1"
            
            placeholder="Top 10 Ports"
            value={formData.field1}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-blue-500"
          />
         {formErrors.field2 && <div className="text-red-500 text-xs mt-1">{formErrors.field2}</div>}
        </div>
          
      
         
        </div>

        <div className="flex   items-center">
         
          <label className="">
            <input
              type="checkbox"
              name="checkbox"
              checked={formData.checkbox}
              onChange={handleInputChange}
              className="form-checkbox text-blue-500 focus:outline-none focus:shadow-outline-blue"
            />
            <span className="ml-2">I am authorized to scan this target and I agree with the Terms of Service.</span>
          </label>
       
        </div>
          <div className="text-center ">
        <button
          type="submit"
         className="lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 xl:px-5 xl:py-2 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3"
        >
          Start Scan
        </button>
        </div>
      </form>
    </div>
     
      </div>
    
    </>
  )
}
