import React from 'react'
import video from '../Images/videos/sknad.mp4'

export const Productherosec = () => {
  return (
    <>
        <div className="bg-[#081226] bg-protop bg-cover items-center  grid  lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16  lg:px-20 xl:py-20 lg:py-20 md:py-20 sm:py-14 py-12 sm:px-10 md:px-16 md:gap-8 sm:gap-6 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className="   xl:text-left  lg:text-left md:text-left sm:text-left text-center xl:mt-20 lg:mt-16 md:mt-16 sm:mt-14 mt-10">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-xl mb-10 ">
          SkandaShield XDR Platform
          </h1>
          <h1 className="font-normal tracking-widest text-[10px] lg:text-sm mb-6 xl:text-lg sm:text-xs md:text-[10px] text-white">
          Our XDR offers a comprehensive suite of capabilities for proactive threat detection, thorough investigation, and efficient response, making it a powerful tool for organizations of all sizes to combat cyber threats effectively.
          </h1>
          <a href="demo">
          <button className="glow-button lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
           Book Demo
          </button></a>
        </div>
        <div className="lg:block xl:block md:block sm:block  xl:pl-16 lg:pl-7 xl:mt-20 lg:mt-16 md:mt-16 sm:mt-14 mt-12">
          <video
            src={video}
            alt=""
            className=" border-2 border-[#79C942] rounded-xl" controls
          >
            </video>
        </div>
      </div>
    
    
    

    
    
    
    
    </>
  )
}
