import React, { useEffect } from "react";

const HeroBackground = () => {
  //   useEffect(() => {
  //     var arrowContainer = document.querySelector(".arrow-container");
  //     arrowContainer.classList.remove("-expand-arrows-animation");
  //     // void arrowContainer.offsetWidth;
  //     arrowContainer.classList.add("-expand-arrows-animation");
  //   }, []);
  return (
    <div className="overflow-hidden absolute top-0 left-0 w-[96vw] h-full">
      <div class="arrow-container overflow-hidden -expand-arrows-animation  w-full h-screen">
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
      </div>
    </div>
  );
};

export default HeroBackground;
