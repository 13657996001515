import React from "react";
import vul from "../Images/Services/how-we-are2.png";

export const Patvul = () => {
  return (
    <>
    <div className="bg-[#081226]  items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-24  lg:px-20  xl:py-20 lg:py-20 md:py-20 sm:py-14 sm:px-10 md:px-9  py-10 ">
    {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
    {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

    <div className="xl:px-16 lg:px-6 sm:px-8 ">
      <img
        src={vul}
        alt=""
        className=" hidden xl:block lg:block md:block sm:block  "
      />
    </div>

    <div className=" xl:text-left  lg:text-left md:text-left sm:text-left text-center ">
      <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-6 ">
      Patch Vulnerabilities
      </h1>
    
      <p className="font-normal tracking-widest text-sm lg:text-sm  mt-3 xl:text-lg sm:text-xs md:text-base text-white">
      Streamline vulnerability patching: Launch your program, receive detailed reports in your SkandaShield portal, and assign tasks directly to your team's workflow (Jira, Github). Quick clarification calls with pentesters through the portal ensure efficient resolution and a stronger security posture.
      </p>
   
    </div>

  </div>
  </>
  );
};
