import React, { useEffect, useRef } from "react";
import block from "../Images/others/homeflownew.drawio.svg";

export const MangSecurity = () => {
  return (
    <>
      <div className="bg-vector1 bg-cover bg-[#081226] xl:py-10 lg:py-1 md:py-10 sm:py-10 py-10  ">
        <h3 className=" text-sm xl:text-5xl  lg:text-4xl md:text-3xl sm:text-xl  font-sans font-bold text-center    mb-5  text-[#79C942]">
     
          SkandaShield: AI Enabled Security Platform 
        </h3>
        <h1 className="text-white xl:text-4xl font-sans font-bold text-center mb-5">
          Increase Cyber Resilience through Threat Management Platform
        </h1>

        <p className=" font-sans font-semibold tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs   text-[#abb8c3] xl:px-24 lg:px-10 sm:px-9 px-6">
          Highly optimized for alerts and minimise false positives to
          continually improve your security posture and reuse your existing
          tools, predict and track your attack surface
        </p>
      </div>
      <div className="bg-[#081226] xl:py-20  lg:py-20 md:py-20  py-10 xl:px-48   lg:px-24 md:px-14 sm:px-10 px-5 sm:py-12 flex justify-center items-center">
        <object
          data={block}
          alt=""
          className=" lg:block xl:block md:block sm:block w-full"
        />
      </div>
    </>
  );
};
