import React from "react";
import { FaCircle } from "react-icons/fa";

export const TopHerosolutions = () => {
  return (
    <>
      <div className="bg-[#081226] bg-solher bg-fixed bg-no-repeat bg-right-top bg-cover items-center   grid lg:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 px-12 xl:px-28 lg:px-20 lg:py-36 md:py-28 sm:py-28 xl:py-40 py-24 sm:px-10 md:px-16  ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className="  xl:text-left  lg:text-left md:text-left sm:text-left  ">
          <h1 className=" font-extrabold text-center text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-xl   ">
            Unparalleled Offensive Security: Fortify Your Organization Against
            Evolving Threats
          </h1>
          <p className="font-normal text-center tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white xl:mb-9 lg:mb-5 md:mb-8 sm:mb-5 mb-4">
            Threats ever-shift, so smart businesses shield assets with proactive
            attacks. SkandaShield mirrors real threats, safeguarding your unique
            risks with expert simulations.
          </p>
      
          <ul class=" text-white  md:text-xs xl:text-base lg:text-sm sm:text-xs text-xs  ">
            <div className="flex gap-4 xl:mx-20 ">
            <div className=" border border-[#79C942] rounded-md p-3 ">
            <li className="flex items-center mb-3">
              {/* <FaCircle className=" text-[#FBD131]" /> */}
              Logic-Driven Attacks: Simulations mirror your unique business,
              exposing real threats.
            </li>
            <li className="flex items-center">
              {/* <FaCircle className=" text-[#FBD131]" /> */}
              Real-World Adversary Play: We test like hackers, revealing true
              security gaps.
            </li>
            </div>


            <div className=" border border-[#79C942] rounded-md p-3">

            <li className="flex items-center mb-3">
              {/* <FaCircle className=" text-[#FBD131]" /> */}
              Manual Vulnerability Hunt: Hands-on exploits uncover hidden risks,
              not just theories.
            </li>
            <li className="flex items-center ">
              {/* <FaCircle className=" text-[#FBD131]" /> */}
              Offensive Security Masters: Our experts know every attacker's
              move, protecting your assets.
            </li>
            </div>
            </div>
          </ul>
         
          <div className="text-center">
            <a href="demo">
            <button className=" lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317] mt-5">
           Book Demo
          </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
