import React from "react";
import manage from "../Images/others/Group57.png";
import "aos/dist/aos.css";

export const AbouTops = () => {
  const Comp = [
    {
      // para:'In-Depth Threat Visibility',
      para1:
        "   Employ the same tools and techniques as today’s hackers to safeguard your data",
    },
    {
      // para:'Monitor Key Metrics',
      para1:
        " All successful exploits are completely documented and archived for future reference",
    },
    {
      // para:'Security Journey',
      para1: " Deliver ready-to-use reports to stakeholders",
    },
  ];
  return (
    <>
      <div className="bg-[#081226] bg-light bg-cover items-center  px-12 xl:px-16  lg:px-20  xl:py-32 lg:py-36 md:py-28 sm:py-32 sm:px-10 md:px-9  py-32 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className="  text-center">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-10">
            About Us
          </h1>
          {/* <h1 className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white mb-10">
          Go beyond detection & response. AI-guided cyber platform predicts & stops attacks before they happen. Secure your future.
          </h1>
          <a href="demo">
          
          <button className=" lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
           Book Demo
          </button>
          </a> */}
        </div>
        {/* <div className="">
          <img
            src={myImage}
            alt=""
            className=" hidden xl:block lg:block md:block sm:block  "
          />
        </div> */}
      </div>

      <>
        <div className=" bg-[#081226] xl:py-16 lg:py-10 md:py-10 sm:py-8 py-10 xl:h-full ">
          <h3 className=" text-sm xl:text-5xl  lg:text-4xl md:text-3xl sm:text-xl font-sans font-bold text-center mb-4  text-[#79C942]">
          <span class="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">
            SkandaShield Security Center </span>
          </h3>
          <h1 className="text-white xl:text-4xl font-sans font-bold text-center xl:px-10 lg:px-10 md:px-10 sm:px-10 ">
            Faster Transparency, Engagement, and Clear Communication within Your
            Security Center for Superior Security Outcomes
          </h1>
        </div>

        <div className="bg-[#081226]  grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-10 lg:py-10 xl:py-14 md:py-16 sm:py-16 xl:px-48 lg:px-20 md:px-16 px-7 sm:px-40 lg:gap-5 md:gap-5  xl:gap-5 sm:gap-9 gap-5">
          {Comp.map((data) => (
            <>
              <div
                data-aos="flip-up"
                data-aos-delay="300"
                data-aos-duration="700"
                className=" border border-[#79C942] xl:p-8 lg:p-8 md:p-8 sm:p-6 p-6 rounded-xl text-white text-center flex flex-col  "
              >
                {/* <h1 className='font-bold xl:text-2xl lg:text-xl md:text-xl sm:text-xl text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 mb-2'>{data.para}</h1> */}
                <p className="xl:text-lg lg:text-base md:text-base sm:text-base  text-sm">
                  {data.para1}
                </p>
              </div>
            </>
          ))}
        </div>
      </>

      <div class="bg-[#081226] xl:p-28 lg:p-16 md:p-12 sm:p-14 p-12  ">
        <div class=" mx-auto bg-[#595B57] bg-opacity-40 p-8 rounded-xl">
          <h1 class="text-[#79C942] text-sm xl:text-5xl  lg:text-4xl md:text-3xl sm:text-xl font-sans font-bold mb-10">
            Here are a handful of our accreditations
          </h1>

          <ul class="list-none text-white  p-0 text-sm xl:text-xl  lg:text-lg md:text-base sm:text-base">
            <li class="mb-10  ">
              <span class="bg-[#79C942]   p-2 rounded-md mr-2">1</span>
              Certified Information System Security Professional (CISSP)(ISC)2
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">2</span>
              Offensive Security Certified Professional (OSCP)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">3</span>
              Offensive Security Certified Expert (OSCE)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">4</span>
              Certified Ethical Hacker (CEH)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">5</span>
              GIAC Certified Intrusion Analyst (GCIA)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">6</span>
              GIAC Certified Incident Handler, SANS Institute (GCIH)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">7</span>
              Certified Cisco Network Associate, Cisco Systems (CCNA)
            </li>
            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">8</span>
              Microsoft Certified Systems Engineer, Microsoft (MCSE)
            </li>

            <li class="mb-10  ">
              <span class="bg-[#79C942] p-2 rounded-md mr-2">9</span>
              Splunk Certified Architect (SCA)
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
