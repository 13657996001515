import React from 'react'

export const Footbott = () => {
  return (
    <>
      <div className="bg-[#081226]  xl:py-20 lg:py-16 md:py-16 sm:py-10 py-10">
        <div className="  bg-aboutus bg-fixed bg-no-repeat bg-right-top bg-cover xl:h-full lg:h-96 ">
          <div className=" xl:py-24 text-center lg:py-24 md:py-28 sm:py-10 py-10">
            <h1 className="text-white xl:text-4xl lg:text-4xl md:text-2xl text-lg font-sans font-bold mb-5 ">
            Skandashield will protect your website in a matter of minutes!
            </h1>

            <p className=" font-sans font-normal tracking-wider  text-[12px] xl:text-lg lg:text-lg md:text-base sm:text-xs  mb-10  lg:mb-20 xl:mb-2 md:mb-20 text-white ">
            Secure your Web, Mobile and IoT assets from malwares and malevolent cybercriminals using SkandaShield platform.
            </p>


            <a href="demo">
          
          <button className=" lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
           Contact Us
          </button>
          </a>
          </div>
        </div>
        </div>
    
    </>
  )
}
