import { IoLogoWhatsapp } from "react-icons/io";

const ScrollToTopButton = () => {
  // const [isVisible, setIsVisible] = useState(false);

  // const handleScroll = () => {
  //   const scrollTop = window.scrollY;
  //   setIsVisible(scrollTop > 300);
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);F
  //   };
  // }, []);

  return (
    <div
      className={`fixed cursor-pointer  bg-white rounded-full  bottom-10  right-5`}
    >
      <a
        href="https://api.whatsapp.com/send?phone=917603931290"
        target="_blank"
      >
        <IoLogoWhatsapp color="green" size={55} />
      </a>
    </div>
  );
};

export default ScrollToTopButton;
