import React, { useState, useEffect } from "react";
import { createClient } from "next-sanity";
import { LuLoader2 } from "react-icons/lu";

export const Formpoint = () => {
  const client = createClient({
    projectId: "bvncdlu7",
    dataset: "production",
    token:
      "skZyLL819cVNxvrouAusMGoMHIQ2GsZX7ZMysIrR3RUiHCfsGCNCkp5KfSzfiaUmxhK5UEhvtJR7YmMG3X1d9BRGTj2Z80ISpcopNIXlm1azKJthVTd979r1TiX208c0WtmkzjPTd3tcxlWeVclHOoEsevIJzgfUpNhCaRxwcHjQjwtScoua",
    apiVersion: "2022-03-25",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    jobTitle: "",
    productInterest: "",
    message: "",
  });
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setIsSubscribing(true);
    e.preventDefault();
    const doc = {
      _type: "customerLead",
      ...formData,
    };

    client
      .create(doc)
      .then((res) => {
        console.log(`Subscribed ${res._id}`);
        setIsSubscribing(false);
        alert("Your query sent successfully");
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        setIsSubscribing(false);
      });
    console.log("Form Data:", formData);
    setFormData({
      firstName: "",
      lastName: "",
      companyName: "",
      companyEmail: "",
      jobTitle: "",
      productInterest: "",
      message: "",
    });
    setIsSubscribing(false);
  };

  async function getData() {
    const data = await client.fetch('*[_type == "customerLead"]');
    console.log(data);
  }

  return (
    <>
      <div className="bg-[#081226] bg-vectordemo xl:py-40 lg:py-36 md:py-20 sm:py-20 py-10 text-white ">
        <h1 className=" font-extrabold text-center  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-xl  ">
          Request a Demo
        </h1>
        <p className="font-normal text-center xl:px-40 lg:px-40 sm:px-10 md:px-10 px-10 text-[10px] lg:text-sm  mt-3 xl:text-lg sm:text-xs md:text-sm   text-white mb-20">
          Get a customized demo to understand how strategic penetration testing
          can provide full control and visibility of your attack surface, reveal
          patterns and deficiencies in your security program and enable your
          organization to improve overall security posture.
        </p>

        <form
          onSubmit={handleSubmit}
          className=" my-8 grid xl:px-72 lg:px-72 md:px-12 sm:px-12 px-12"
        >
          <div className="flex flex-col sm:flex-row  justify-around xl:gap-x-2 lg:gap-x-2 md:gap-x-2 sm:gap-x-2 gap-x-2">
            <div className="mb-4 xl:w-full lg:w-full md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                First Name*
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>

            <div className="mb-4 xl:w-full lg:w-full md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                Last Name*
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row  justify-around xl:gap-x-2 lg:gap-x-2  md:gap-x-2 sm:gap-x-2 gap-x-2">
            <div className="mb-4 xl:w-full lg:w-full md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                Company Name*
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>

            <div className="mb-4 xl:w-full lg:w-full md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                Company Email Address*
              </label>
              <input
                type="email"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                required
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>
          </div>

          <div
            className="flex flex-col sm:flex-row justify-around xl:gap-x-2 lg:gap-x-2 md:gap-x-2 sm:gap-x-2 gap-x-2
          "
          >
            <div className="mb-4 xl:w-full lg:w-full  md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                Job Title*
              </label>
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>

            <div className="mb-4 xl:w-full lg:w-full  md:w-full sm:w-full w-full">
              <label className=" text-sm font-semibold text-white">
                What Product are you interested?
              </label>
              <input
                type="text"
                name="productInterest"
                value={formData.productInterest}
                onChange={handleChange}
                className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold text-white">
              Write your message*
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full block px-4 py-2 mt-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
            />
          </div>

          <button
            disabled={isSubscribing}
            className=" lg:font-semibold text-center text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3"
          >
            {isSubscribing ? (
              <LuLoader2
                color="white"
                className="animate-spin mx-auto"
                size={30}
              />
            ) : (
              " Submit"
            )}
          </button>
        </form>
      </div>
    </>
  );
};
