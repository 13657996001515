import React, { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import imageUrlBuilder from "@sanity/image-url";
import { IoShareOutline } from "react-icons/io5";

export const Blogmed = (props) => {
  const builder = imageUrlBuilder({
    projectId: "fqwcc10o",
    dataset: "production",
    apiVersion: "2022-03-25",
  });

  function urlFor(source) {
    return builder.image(source);
  }
  const [blog, setBlog] = useState(null);
  useEffect(() => {
    setBlog(props.blog);
    console.log(props.blog);
  }, []);
  return (
    <div className=" bg-[#081226] text-white   py-28 min-h-screen px-5 md:px-32 lg:px-96">
      {blog && (
        <>
          {/* <div className="w-full flex flex-row items-start justify-between"> */}
          <h1 className="w-full text-white text-2xl lg:text-[42px] lg:leading-[50px] font-sans font-bold text-left mb-10">
            {blog.title}
          </h1>
          <div className="w-full relative mb-20 border-b-2 border-white/20 pb-5 flex flex-row gap-5 items-center">
            <div className="h-10 w-10 rounded-full bg-gradient-to-tr from-blue-500 to-teal-400"></div>
            <div className="">
              <p className="text-sm mb-1 opacity-90 ">Author@Skanda</p>
              <p className="text-xs opacity-70">
                Published in <b>Skanda blogs</b> &nbsp; · &nbsp;
                {blog.readingTime} min read &nbsp; · &nbsp;{" "}
                {blog?.publishedAt?.split("T")[0]}
              </p>
            </div>
            <a href="" className="lg:absolute right-3">
              <IoShareOutline color="white" size={28} />
            </a>
          </div>
          {/* <p className="text-[#79C942] opacity-80 text-xl">
              {blog && blog.publishedAt.split("T")[0]}
            </p> */}
          {/* </div> */}
          {blog && (
            <img
              className="h-[50vh]  object-contain  mb-20"
              src={urlFor(blog?.mainImage).url()}
            />
          )}
          {blog && (
            <PortableText
              dataset="production"
              projectId="12zbj1ir"
              content={blog.body}
              serializers={{
                unknownType: ({ node }) => {
                  if (node._type === "code") {
                    console.log(node);
                    return (
                      <SyntaxHighlighter
                        language={node.language}
                        customStyle={{
                          textShadow: "none",
                        }}
                      >
                        {node.code}
                      </SyntaxHighlighter>
                    );
                  }
                },
                h1: (props) => (
                  <h1 className="text-2xl font-extrabold my-5" {...props} />
                ),
                h2: (props) => (
                  <h2 className="text-2xl font-extrabold my-5" {...props} />
                ),
                h3: (props) => (
                  <h3 className="text-2xl font-extrabold my-5" {...props} />
                ),
                h4: (props) => (
                  <h4 className="text-2xl font-extrabold my-5" {...props} />
                ),
                normal: (props) => (
                  <p
                    className=" mb-9 opacity-80 font-serif leading-8"
                    {...props}
                  />
                ),

                li: ({ children }) => (
                  <li className="ml-4 list-disc">{children}</li>
                ),
                link: ({ href, children }) => (
                  <a href={href} className="text-blue-500 hover:underline">
                    {children}
                  </a>
                ),
                image: ({ asset }) =>
                  asset ? (
                    <img
                      className="object-contain h-fit mx-auto my-10"
                      src={urlFor(asset).url()}
                    />
                  ) : (
                    <div></div>
                  ),
              }}
            />
          )}
        </>
      )}
      {/* <div className="text-lg text-[#79C942] flex justify-around xl:mb-10 underline">
        <a href="">More posts</a>
        <a href="">To Top</a>
      </div> */}
    </div>
  );
};
