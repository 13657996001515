import React from "react";
import gif from "../Images/others/servflow.svg";

export const CoroServices = () => {
  return (
    <>
    <div className="bg-[#081226] bg-protop bg-cover  items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16  lg:px-20  xl:py-28 lg:py-28 md:py-24 sm:py-20 sm:px-10 md:px-9  py-20 xl:gap-16 lg:gap-12 md:gap-14 sm:gap-8 xl:mt-8 lg:mt-10 md:mt-6 sm:mt-7 mt-8">
    {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
    {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

    <div className=" xl:text-left  lg:text-left md:text-left sm:text-left text-center ">
      <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-2xl mb-7 ">
      PTaaS – Pentesting as a Service
      </h1>
      <h1 className=" font-bold  text-[#FBD131] text-xl lg:text-3xl xl:text-3xl md:text-2xl sm:text-xl mb-7">
      Agile, Easier and Smarter Pentests
      </h1>
      <p className="font-normal tracking-widest text-xs lg:text-sm  xl:text-lg sm:text-xs md:text-base text-white">
      SkandaShield PTaaS leverages automation and human expertise on-demand to streamline your security and compliance journey. From startups to enterprises, organizations trust us to simplify security challenges.
      </p>
   
    </div>
    <div className="xl:px-16 lg:px-6 md:px-2 sm:px-1 px-1  xl:mt-2 lg:mt-4 md:mt-4 sm:mt-1 mt-10">
      <object
        data={gif}
        alt=""
        className=" xl:block lg:block md:block sm:block  w-full"
      />
    </div>
  </div>
  </>
  );
};
