import React from "react";

import pre from "../Images/icons/permi.png";
import clo from "../Images/icons/clo.png";
import hyp from "../Images/icons/hyb.png";
import { auth, provider } from "../firebaseconfig";
import { signInWithPopup } from "firebase/auth";
import { signIn } from "../App";

export const Environments = () => {
  const Comp = [
    {
      icon: pre,
      title: "On-premises",
      po1: "Internal systems",
      po2: "Legacy applications",
      po3: "User networks",
    },
    {
      icon: clo,
      title: "Cloud",
      po1: "IaaS",
      po2: "Containers",
      po3: "Serverless / APIs",
    },
    {
      icon: hyp,
      title: "Remote Workers",
      po1: "Internal systems",
      po2: "Shared Services​",
      po3: "Cloud Storage",
    },
  ];
  return (
    <>
      <div className="bg-[#081226] ">
        <div className="bg-vectorproduct bg-cover  xl:py-10  lg:py-10 md:py-8 sm:py-10 py-10 ">
          <h1 className="text-[#79C942] xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-2xl font-sans font-bold text-center mb-5  ">
            Environments
          </h1>

          <p className="xl:px-40 lg:px-36 sm:px-10 font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs  text-white ">
            Skandashield provides extensive coverage of on-prem and cloud
            environments to identify vulnerabilities and misconfigurations with
            attack path maps for each security gap.
          </p>
        </div>

        <div className="bg-[#081226] w-full grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 text-center xl:py-16 xl:px-32 md:px-10 px-16 xl:gap-28 md:py-12 lg:py-16 sm:py-16 py-12 lg:px-20 sm:px-44 lg:gap-24 md:gap-10 sm:gap-10 gap-10">
          {Comp.map((data, index) => (
            <div
              data-aos="flip-left"
              data-aos-delay="100"
              data-aos-duration="700"
              className="text-white  flex  flex-col relative items-center border rounded-lg border-[#79C942] xl:p-8 lg:p-8 md:p-8 sm:p-4 p-4"
            >
              <img
                src={data.icon}
                alt=""
                className="xl:mb-4 absolute xl:-left-8 xl:top-1/2 lg:-left-6 lg:top-1/2  md:-left-5 md:top-1/2  sm:-left-4 sm:top-1/2   -left-4 top-1/2    -translate-y-1/2 xl:h-16 lg:h-12 md:h-10 sm:h-8 h-10 "
              />

              <h1 className="text-[#FBD131] font-bold xl:text-2xl  lg:text-xl md:text-xl sm:text-xl text-base xl:mb-4 lg:mb-4">
                {data.title}
              </h1>
              {/* <img src={data.img} alt="" className="xl:mb-4" /> */}
              <h2 className="xl:text-xl  font-bold">{data.name}</h2>
              <div className="">
                <ul className="text-left disc">
                  <li>{data.po1}</li>
                  <li>{data.po2}</li>
                  <li>{data.po3}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="xl:py-20 lg:py-16 md:py-16 sm:py-10 py-10">
          <div className=" bg-[#081226] bg-last bg-fixed bg-no-repeat bg-right-top bg-cover xl:h-full lg:h-96 ">
            <div className="xl:ml-56 xl:py-24 lg:ml-40 md:ml-20 sm:ml-16 ml-12 lg:py-24 md:py-28 sm:py-10 py-10">
              <h1 className="text-white xl:text-4xl lg:text-4xl md:text-2xl text-lg font-sans font-bold mb-5 ">
                Ready to learn more?
              </h1>

              <p className=" font-sans font-normal tracking-wider  text-[12px] xl:text-lg lg:text-lg md:text-base sm:text-xs  mb-10  lg:mb-20 xl:mb-5 md:mb-20 text-white ">
                Talk to our techincal sales team to get your questions answered
              </p>

              <a href="demo">
                <button className="glow-button lg:font-semibold text-[10px] px-2 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-3  rounded-md  text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
                  Book Demo
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center py-12">
          <button
            onClick={() => {
              if (!localStorage.getItem("userEmail")) {
                signIn();
              }
            }}
            className="font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] "
          >
            {localStorage?.getItem("userEmail") ? (
              <a href="/pdf/ProductCatalogue.pdf" download="ProductCatalogue">
                DOWNLOAD PRODUCT CATALOGUE NOW
              </a>
            ) : (
              "DOWNLOAD PRODUCT CATALOGUE NOW"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
