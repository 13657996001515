import React from "react";
import insta from "../Images/icons/insta.png";
import linked from "../Images/icons/linked.png";
import twitter from "../Images/icons/twi.png";
import facebook from "../Images/icons/face.png";
import youtube from "../Images/icons/youtube.png";
import logo from "../Images/logo/Group15png 1.png";

export const FooterTab = () => {
  return (
    <>
      {/* <footer
        className="bg-[#081226] text-white   shadow-white xl:py-10 lg:py-10 md:py-16"
        id="footer"
      >
        <div className=" flex flex-wrap  justify-around">
        
          <div className=" mb-4 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-8 ">
            <h2 className="text-2xl font-semibold mb-4 text-[#79C942]">
              ABOUT US
            </h2>
            <p>End to End Enterprise Cybersecurity Servicest</p>
            <img
              className="xl:mt-5 lg:mt-5 md:mt-4 xl:h-12 lg:h-12 md:h-9 sm:h-10 h-9"
              src={logo}
              alt=""
            />
          <p className="xl:mt-28 lg:mt-20 md:mt-24 text-[#79C942]">Terms and Conditions / Privacy Policy / Sitemap</p>

          </div>





     
          <div className=" mb-4 sm:mb-6 md:mb-8 lg:mb-10 ">
            <h2 className="text-2xl font-semibold mb-4 text-[#79C942]">
              CONTACT US
            </h2>
            <p>
              2nd Main Road, Gandhi nagar, Adyar, Chennai
              <br />
              Phone: +91 9962277200
              <br />
              Email: Contact@skandashield.com
            </p>
            <div className="flex xl:mt-5 lg:mt-5 md:mt-5">
              <img className="" src={insta} alt="" />
              <img className="xl:ml-2 lg:ml-2 md:ml-2" src={linked} alt="" />
              <img className="xl:ml-2 lg:ml-2 md:ml-2" src={twitter} alt="" />
              <img className="xl:ml-2 lg:ml-2 md:ml-2" src={facebook} alt="" />
            </div>
          <p className="xl:mt-20 lg:mt-10 md:mt-10 text-[#79C942]">Copyright 2024 © Skandashield All Rights Reserved</p>
            
          </div>
        </div>

        
      </footer> */}
      {/* <div className="flex justify-around text-[#79C942]">
          <p>Terms and Conditions / Privacy Policy / Sitemap</p>
          <p>Copyright 2024 © Skandashield All Rights Reserved</p>
        </div> */}



      <footer  id="footer" className="bg-gray-900 p-8 flex flex-wrap justify-around ">
      <div className="mb-4 md:mb-0">
        {/* Content for the left column */}
        <h2 className="text-2xl font-semibold mb-4 text-[#79C942]">
              ABOUT US
            </h2>
            <p className="text-white">End to End Enterprise Cybersecurity Services</p>
            <img
              className="xl:mt-5 lg:mt-5 md:mt-4 sm:mt-3 mt-3 xl:h-12 lg:h-12 md:h-9 sm:h-10 h-9"
              src={logo}
              alt=""
            />
      </div>
      <div>
        {/* Content for the right column */}
        <h2 className="text-2xl font-semibold mb-4 text-[#79C942]">Contact Us</h2>
        <p  className="text-white">
              2nd Main Road, Gandhi nagar, Adyar, Chennai
              <br />
              Phone: +91 9962277200
              <br />
              Email: contact@skandashield.com
            </p>
            <div className="flex items-center xl:mt-5 lg:mt-5 md:mt-5 sm:mt-3 mt-3">
              <a href="https://www.instagram.com/skanda_shield/" target="_blank"><img className="" src={insta} alt="" /></a>
              <a href="https://www.linkedin.com/showcase/skandashield/" target="_blank"><img className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2" src={linked} alt="" /></a>
              <a href="https://twitter.com/skandashield" target="_blank"><img className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2"  src={twitter} alt="_blank" /></a>
              <a href="https://www.facebook.com/profile.php?id=100088429186090 " target="_blank">  <img className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2" src={facebook}  alt="" /></a>
              <a href="https://youtube.com/@SKANDASHIELD?si=jpWMYeB9oeR0qvAz " target="_blank">  <img className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2" src={youtube}  alt="" /></a>
            </div>
      </div>
    
    </footer>

    <footer  id="footer" className="bg-gray-900 p-8 flex flex-col md:flex-row md:justify-around">
      <div className="mb-4 md:mb-0">
       
        <p className="text-[#79C942]">Terms and Conditions / Privacy Policy / Sitemap</p>
      </div>
      <div>
       
        <p className="text-[#79C942]">Copyright 2024 © Skandashield All Rights Reserved</p>
      </div>
    
    </footer>
    </>
  );
};
