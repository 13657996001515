import React from 'react'

import threed from '../Images/others/auto.png'

export const Penteresting = () => {
  return (
   <>
       <div className="bg-[#081226]  items-center  grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-12 xl:px-16 lg:px-20  md:gap-12  lg:py-14 md:py-16 sm:py-12 xl:py-24 py-8 sm:px-14 md:px-16  gap-5 sm:gap-5 xl:gap-10 lg:gap-12 ">
        {/* <img src={forest} alt="" className="w-full h-full absolute top-0 left-0 object-cover"/> */}
        {/* <img src={myImage} alt="" className="w-full h-48  absolute left-1/3 bottom-20 animate-bounce"/> */}

        <div className="">
          <h1 className=" font-extrabold  text-[#79C942] text-2xl lg:text-5xl xl:text-5xl md:text-3xl  ">
          Start Automated Pentesting Today
          </h1>
          <p className="font-normal tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-xs md:text-[10px] text-white">
          Detect easily vulnerabilities in your web applications and APIs before you push the release button.
          </p>
          <a href="demo">
          <button className="glow-button lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
            Book Demo
          </button>
          </a>
        </div>
        <div className="">
          <img
            src={threed}
            alt=""
            className=" xl:block lg:block md:block sm:block hidden"
          />
        </div>
      </div>
   
   
   </>
  )
}
