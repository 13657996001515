import React from "react";
import { HowitSteps } from "../Solution/Howit";
import { Projectmin } from "../Solution/Project";
import { Penteresting } from "../Solution/Pent";
import { signIn } from "../App";

export const ImageCardRow = () => {
  const Comp = [
    {
      title: "Secure",
      para: "We use two-factor auth, SSL-only site, run frequent pentests and many other things to secure your data.",
    },
    {
      title: "Collaborative",
      para: "Get descriptions, screenshots and suggested fixes. Need more? Ask pentesters directly.",
    },
    {
      title: "Recommendations",
      para: "Our recommendation engine plus smart filtering gives your reports high signal and low noise.",
    },
    // {
    //   title:'SkandaShield CENTRAL',
    //   para:"Our secure reporting app delivers essential information to you cleanly and simply."
    // },
    // {
    //   title:'Clarity',
    //   para:"All the findings and reports delivered are as clearly written and presented as possible, and immediately actionable by you"
    // },
  ];
  return (
    <>
      <div className="bg-serv bg-cover bg-[#081226] xl:py-10 lg:py-8 md:py-6 sm:py-6 py-5">
        <h3 className="text-xl xl:text-4xl lg:text-4xl md:text-3xl sm:text-xl font-sans font-bold text-center text-[#79C942]">
          Pentest as a Service
        </h3>
        <p className="font-sans font-normal tracking-wider text-center text-[12px] xl:text-lg lg:text-lg md:text-base sm:text-xs mt-3 px-8 text-white">
          SkandaShield offers a modern application security platform that
          supports a complete find-to-fix workflow for all your pentesting and
          vulnerability assessments throughout your organization.
        </p>
      </div>

      <div className="bg-[#081226]  xl:gap-10 lg:gap-10 md:gap-10 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 sm:gap-5 gap-6 xl:py-32 md:py-24 sm:py-16 py-10 lg:py-24 xl:px-10 lg:px-10 md:px-11 sm:px-10 px-16 ">
        {Comp.map((data, index) => (
          <div
            key={index}
            className="  shadow-lg  overflow-hidden transform hover:scale-105 duration-300  text-white rounded-xl border-2 border-[#3C7317] flex flex-col xl:p-10 lg:p-10 md:p-8 sm:p-3 p-3"
          >
            <h1 className="font-bold xl:text-2xl lg:text-2xl md:text-xl sm:text-base text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942]">
              {data.title}
            </h1>
            <p className="xl:text-xl lg:text-xl md:text-lg sm:text-xs text-xs">
              {data.para}
            </p>
          </div>
        ))}
      </div>

      <HowitSteps />
      <Projectmin />
      <Penteresting />
      <div className="bg-[#081226] flex justify-around py-14 px-24 gap-10">
        <button
          onClick={() => {
            if (!localStorage.getItem("userEmail")) {
              signIn();
            }
          }}
          className="font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] "
        >
          {localStorage?.getItem("userEmail") ? (
            <a href="/pdf/usecases.pdf" download="UseCases">
              DOWNLOAD USERCASES NOW
            </a>
          ) : (
            " DOWNLOAD USERCASES NOW"
          )}
        </button>
        <button
          onClick={() => {
            if (!localStorage.getItem("userEmail")) {
              signIn();
            }
          }}
          className="font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] "
        >
          {localStorage?.getItem("userEmail") ? (
            <a href="/pdf/datasheet.pdf" download="Data sheet">
              DOWNLOAD DATASHEET NOW
            </a>
          ) : (
            "DOWNLOAD DATASHEET NOW"
          )}
        </button>
        <button
          onClick={() => {
            if (!localStorage.getItem("userEmail")) {
              signIn();
            }
          }}
          className="font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] "
        >
          {localStorage?.getItem("userEmail") ? (
            <a href="/pdf/whitepaper.pdf" download="White paper">
              DOWNLOAD WHITEPAPER NOW
            </a>
          ) : (
            "DOWNLOAD WHITEPAPER NOW"
          )}
        </button>
        <button
          onClick={() => {
            if (!localStorage.getItem("userEmail")) {
              signIn();
            }
          }}
          className="font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] "
        >
          {localStorage?.getItem("userEmail") ? (
            <a
              href="/pdf/PTaasServicesCatalogue.pdf"
              download="PTaasServicesCatalogue"
            >
              DOWNLOAD PTaaS SERVICE CATALOGUE NOW
            </a>
          ) : (
            "DOWNLOAD PTaaS SERVICE CATALOGUE NOW"
          )}
        </button>
      </div>
    </>
  );
};
