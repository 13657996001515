import React from 'react'
import manage from "../Images/others/Group57.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


export const SecurityCenter = () => {

    const Comp = [
        {
            para:'In-Depth Threat Visibility',
            para1:"Take control of your security posture: Dive deeper with insightful metrics, KPIs, and reports tailored to your operations to Make data-driven decisions."
          },
          {
            para:'Monitor Key Metrics',
            para1:"Gain a clear view of your security posture with tactical and strategic metrics insights. SkandaShield experts guide you every step of the way"
          },
          {
            para:'Security Journey',
            para1:"Never settle for 'Good Enough' in security. SkandaShield's Security Score guides your evolution, ensuring ongoing progress and a constantly strengthened digital fortress."
          },
        ]
  return (
    <>
     <div className="bg-vector1 bg-cover bg-[#081226] xl:py-16 lg:py-10 md:py-10 sm:py-8 py-10 xl:h-full ">
        <h3 className=" text-sm xl:text-5xl  lg:text-4xl md:text-3xl sm:text-xl font-sans font-bold text-center mb-4  text-[#79C942]">
     
        SkandaShield Security Center
        </h3>
        <h1 className='text-white xl:text-4xl font-sans font-bold text-center xl:px-10 lg:px-10 md:px-10 sm:px-10 '>Faster Transparency, Engagement, and Clear Communication within Your Security Center for Superior Security Outcomes</h1>
      </div>

      <div className="bg-[#081226] xl:py-14 lg:py-16 md:py-16 sm:py-14 py-12 xl:px-60 lg:px-40 md:px-20 sm:px-12 px-5 justify-center">
        <img
          src={manage}
          alt=""
          className="  "
        />
      </div>

      <div  className="bg-[#081226]  grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-10 lg:py-10 xl:py-16 md:py-16 sm:py-16 xl:px-10 lg:px-20 md:px-16 px-7 sm:px-20 lg:gap-5 md:gap-5  xl:gap-5 sm:gap-9 gap-5">
            {Comp.map((data) => (
                <>
                <div  data-aos="flip-up" data-aos-delay="300"     data-aos-duration="700"
 className=' border border-[#79C942] xl:p-8 lg:p-8 md:p-8 sm:p-6 p-6 rounded-xl text-white text-center flex flex-col  ' >
                <h1 className='font-bold xl:text-2xl lg:text-xl md:text-xl sm:text-xl text-base xl:mb-3 lg:mb-2 md:mb-2 sm:mb-2 mb-2'>{data.para}</h1>
                <p className='xl:text-lg lg:text-base md:text-base sm:text-base  text-sm'>{data.para1}</p>
               

                </div>

                </>
        ))}
        </div>
    
    </>
  )
}
