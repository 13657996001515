import React from "react";
import video from "../Images/videos/fornt.mp4";

export const VideoSection = () => {
  return (
    <>
      <div className="  bg-[#081226]  xl:py-10  lg:py-10 md:py-10 sm:py-10 py-10 xl:h-full  ">
        <h3 className=" text-xl xl:text-6xl  lg:text-4xl md:text-3xl sm:text-xl  text-center mb-8  text-white">
        <span class="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">
          Fortify Your Digital Fortress
          </span>
        </h3>
        <p className=" font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs  px-8 lg:mb-0 text-white">
          Unleash Advanced Threat Detection and Expert-Driven Response
        </p>
      </div>
      <div className="bg-[#081226]  xl:py-20 lg:py-20 md:py-16 sm:py-10 xl:px-80 lg:px-48 md:px-32 sm:px-20 px-10 py-10">
      <video src={video} type=""  width="" height="360" className="border-2 border-[#79C942] rounded-3xl" controls>
    
        Your browser does not support the video tag.
      </video>
      </div>
    </>
  );
};
