import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";

const Blogs = (props) => {
  const [blogs, setBlogs] = useState(null);
  useEffect(() => {
    setBlogs(props.blogs);
  }, []);

  useEffect(() => {
    setBlogs(props.blogs);
  }, [props.blogs]);
  const builder = imageUrlBuilder({
    projectId: "fqwcc10o",
    dataset: "production",
    apiVersion: "2022-03-25",
  });

  function urlFor(source) {
    return builder.image(source);
  }
  return (
    <div className=" bg-[#081226] text-white flex items-start  w-full py-20 lg:py-28 min-h-screen max-h-fit lg:px-20 md:px-10 px-5 ">
      <div className=" grid grid-cols-1 w-full lg:w-3/4 gap-12 xl:px-20 lg:px-16 md:px-10 sm:px-6 text-center">
        {blogs && blogs.length >= 1 ? (
          blogs.map((blog) => (
            <a href={`/blog/${blog?.slug?.current}`}>
              <div className="p-5 flex flex-row gap-10 items-center border-b-2 text-left border-white/20 text-white">
                <div className="w-4/5">
                  <div className="w-full mb-3 flex flex-row gap-2 items-center">
                    <div className="h-7 w-7 rounded-full bg-gradient-to-tr from-blue-600 to-teal-400"></div>
                    <p className="text-xs opacity-50 ">
                      Author@Skanda · {"  "}
                      {blog?.publishedAt?.split("T")[0]}
                    </p>
                  </div>
                  <h2 className="font-extrabold text-xl mb-2">{blog?.title}</h2>
                  <p className="text-white/50 text-sm mb-6">
                    {blog?.description}
                  </p>
                  <div className="w-full my-3 flex flex-row gap-2 justify-between items-center">
                    <div className="w-fit  flex flex-row gap-2 items-center">
                      {blog?.tags &&
                        blog?.tags?.length > 0 &&
                        blog?.tags.map((tag) => (
                          <p className="bg-white/30 text-xs px-3 py-3 rounded-3xl">
                            {tag}
                          </p>
                        ))}
                    </div>
                    <p className=" text-xs opacity-70 pr-5">
                      {blog?.readingTime} min read
                    </p>
                  </div>
                </div>
                <div className="w-32 h-32 xl:block lg:block md:block sm:hidden hidden bg-red-50">
                  <img
                    className="w-full h-full object-cover"
                    src={urlFor(blog?.mainImage).url()}
                  />
                </div>
              </div>
            </a>
          ))
        ) : (
          <p>No Blogs available right now.</p>
        )}
      </div>
      <div className="w-2/5 px-10 testimonial overflow-y-scroll h-[80vh] border-l-2 border-white/10 flex flex-col gap-7 xl:block lg:block md:block sm:hidden hidden">
        <p className="font-semibold text-white/90 mb-3">Author picks</p>
        {blogs &&
          blogs.map((blog) => (
            <div className="w-full border-b-2 border-white/10 pb-5 mt-4">
              <div className="w-full mb-3 flex flex-row gap-2 items-center">
                <div className="h-6 w-6 rounded-full bg-gradient-to-tr from-blue-600 to-teal-400"></div>
                <p className="text-xs font-semibold opacity-50 ">
                  Author@Skanda
                </p>
              </div>
              <h2 className="font-extrabold w-[90%] mb-2 opacity-80">
                {blog?.title}
              </h2>
            </div>
            
          ))}
        {blogs &&
          blogs.map((blog) => (
            <div className="w-full border-b-2 border-white/10 pb-5 mt-4">
              <div className="w-full mb-3 flex flex-row gap-2 items-center ">
                <div className="h-6 w-6 rounded-full bg-gradient-to-tr from-blue-600 to-teal-400"></div>
                <p className="text-xs font-semibold opacity-50 ">
                  Author@Skanda
                </p>
              </div>
              <h2 className="font-extrabold w-[90%] mb-2 opacity-80">
                {blog?.title}
              </h2>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Blogs;
