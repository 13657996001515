import React from 'react'

import arrow from '../Images/icons/arrow.png'


export const HowitSteps = () => {

    const Comp = [
        {
          
        title:"Step 1",
        para:"You ask for a penetration test",
        icon: arrow,
     
        },
        {
            title:"Step 2",
            para:"Our security pros run the test",
            icon:arrow,
        },
        {
            title:"Step 3",
            para:"You get  adetailed pentest report",
            icon:arrow,
        },
        {
            title:"Step 4",
            para:"You tackle the security issuest",
            icon:'',
         
        },
      ];
  return (
   <>
   <div className="bg-vectorsolution bg-[#081226] xl:py-10 lg:py-12 md:py-8 sm:py-8 py-6  xl:px-36 ">
       
       <h1 className='text-[#79C942] xl:text-5xl lg:text-4xl md:text-3xl sm:text-3xl text-xl font-sans font-bold text-center mb-6'>
       How it works</h1>

       <p className=" font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-sm  text-white xl:px-20 ">
       Security Made Easy: Get a quote, book, & launch your test. Simple as that.
       </p>
       {/* <div className='text-center'>
          <button className=" lg:font-semibold text-[14px] px-4 py-2 lg:text-sm sm:text-xs lg:px-6 lg:py-4 md:text-[12px] md:px-4 md:py-3 sm:px-3 sm:py-2  rounded-md   text-white bg-[#3C7317]  lg:mt-8 md:mt-4 sm:mt-4 mt-3">
            Book Demo
          </button>
          </div> */}
     </div>
     <div className="bg-[#081226] text-center justify-evenly xl:px-16 md:px-8 xl:py-32 md:py-24 sm:py-12 py-10 xl:gap-10 md:gap-6 lg:py-20 lg:px-9 px-14 sm:px-10  lg:gap-6 sm:gap-3 gap-3 flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
        {Comp.map((data, index) => (
          <>
            <div className="text-white flex flex-col  items-center border rounded-lg border-[#79C942] xl:p-6 lg:p-5 md:p-5 sm:p-5 p-4">
            {/* <img src={data.img} alt="" className="xl:mb-4"/> */}
              <h1 className="xl:mb-4 lg:mb-3 xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl text-base text-[#79C942]" >{data.title}</h1>
           
              
              <p className="xl:text-xl lg:text-xl md:text-base sm:text-sm text-xs">{data.para}</p>
            </div>
            <div className='flex   items-center'> 
          
            {index != Comp.length-1 && <img src={data.icon} alt="" className="xl:block lg:block md:block sm:block hidden xl:h-6 xl:w-12 lg:h-4 lg:w-14 sm:h-4 sm:w-14 md:h-4 md:w-12 h-3 w-6  "/>}
            </div>
          </>
        ))}
      </div>
   
   
   </>
  )
}
