import React from "react";
import dec from "../Images/icons/dect.png";
import inv from "../Images/icons/inves.png";
import res from "../Images/icons/Responsive.png";
import imp from "../Images/icons/improve.png";
import dot from "../Images/Product/newpro.svg";

export const Saasapp = () => {
  const Comp = [
    {
      name: "DETECT",
      icon: dec,
      title: "Advanced Threat Detection",
      para: "We detect threats no one else does by applying advanced analytics to volumes of security telemetry in the places where adversaries operate.",
      title1: "Continuous Threat Hunting",
      para1:
        "When we detect a threat to one of our thousands of customers, we’re immediately hunting for it across your environment.",
      title2: "Global Focus on Threats",
      para2:
        "Our intelligence analysts are experts on advanced adversaries, from state-sponsored to criminal and everything in between.",
    },

    {
      name: "INVESTIGATE",
      icon: inv,

      title: "24×7 Monitoring & Investigations",
      para: "Put an end to the noise. We investigate threats and alerts detected by Red Canary and your existing security investments 24/7/365.",

      title1: "Handcrafted Explanations",
      para1:
        "When we confirm a threat or eliminate a false positive, you’ll understand what we concluded and why—using language anyone on your team can understand.",

      title2: "Useful Adversary Intelligence",
      para2:
        "We could focus on the threat actors the media is hyping, but we’d think you’d rather get actionable intel. We’ll always show you how adversaries operate and how we detect their behaviors.",
    },

    {
      name: "RESPOND",

      icon: res,
      title: "Automation & Orchestration",
      para: "Playbooks allow you to notify the right people, contain threats, and begin remediation. And they’re already baked into our platform.  ",

      title1: "Active Remediation",
      para1:
        "You don’t have to do it all. Offload remediation of confirmed threats to our team, and we’ll handle 24×7 response on your behalf.",

      title2: "Incident Response",
      para2:
        "Rapid response could mean the difference between one affected system or 100. Our teams work with yours, minute by minute, to stop the spread.",
    },

    {
      name: "IMPROVE",

      icon: imp,
      title: "24×7 Support",
      para: "Contact us any time you have a question, concern, or just want to talk about the latest threats. We’re here when you need us, and it never costs more.",

      title1: "Executive Reporting",
      para1:
        "Track your MTTR, ROI, and the work we’re doing with full transparency so you (and your leadership) know how your program is improving over time.",

      title2: "Testing & Validation",
      para2:
        "You shouldn’t have to guess if your products and services are actually detecting modern threats. We’ll help you test and validate so you know for sure.",
    },
  ];
  return (
    <>
      <div className="bg-vectorproduct bg-cover bg-[#081226] xl:py-10 lg:py-8 md:py-8 sm:py-6 py-6  ">
        <p className="text-[#79C942]   xl:text-5xl lg:text-4xl md:text-3xl text-xl font-sans font-bold text-center mb-5 lg:mb-5 xl:px-64  lg:px-20 md:px-14 sm:px-14 px-8 ">
        We protect your Endpoints, Network, Cloud Workloads, Identities, and SaaS applications:
        </p>

        <p className="xl:px-40 lg:px-10 sm:px-10 px-10 font-sans font-normal tracking-wider text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs  text-white ">
        Forget the reactive scramble. We're your proactive shield, anticipating and mitigating threats before they cause damage
        </p>
      </div>

      {/* <div className="bg-[#081226] grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 lg:py-10 sm:py-16 xl:py-32 md:py-24 md:gap-y-24 sm:gap-y-10 gap-y-12 xl:gap-y-52  py-12">
        {Comp.map((data, index) => (
          <>
            <div
              className={` text-white  flex  flex-col  ${
                index != Comp.length - 1 && "border-r"
              }  xl:px-10 lg:px-8 md:px-6 sm:px-10 px-10 " `}
            >
              <div className="flex items-center xl:mb-10 lg:mb-8 md:mb-10 sm:mb-4 mb-3">
                <img
                  src={data.icon}
                  alt=""
                  className="  mr-3  lg:h-12 lg:w-12  xl:h-12 xl:w-12   md:w-10 md:h-10 sm:w-10 sm:h-10   h-10 w-10  "
                />
                <h1 className="font-bold xl:text-2xl  ">{data.name}</h1>
              </div>

              <h3 className="font-bold xl:text-xl lg:text-xl md:text-xl sm:text-xl text-base xl:mb-2 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942] ">
                {data.title}
              </h3>
              <p className="mb-16 xl:text-sm lg:text-sm md:text-base sm:text-sm text-xs">
                {data.para}
              </p>
              <h3 className="font-bold xl:text-xl lg:text-xl md:text-xl sm:text-xl text-base xl:mb-2 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942]">
                {data.title1}
              </h3>
              <p className="mb-16 xl:text-sm lg:text-sm md:text-base sm:text-sm text-xs">
                {data.para}
              </p>
              <h3 className="font-bold xl:text-xl lg:text-xl md:text-xl sm:text-xl text-base xl:mb-2 lg:mb-2 md:mb-2 sm:mb-2 text-[#79C942]">
                {data.title2}
              </h3>
              <p className="mb-16 xl:text-sm lg:text-sm md:text-base sm:text-sm text-xs">
                {data.para}
              </p>
             
            </div>
          </>
        ))}
      </div> */}
      <div className="bg-[#081226] xl:py-20 xl:px-28 lg:py-10 md:py-14 lg:px-10 md:px-10 sm:px-9 sm:py-12">
        <object
          data={dot}
          alt=""
          className=" lg:block xl:block md:block sm:block hidden justify-center items-center w-full"
        />
      </div>
    </>
  );
};
