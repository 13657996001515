import React from 'react'
import bhaskar from "../Images/aboutus/Group 1571.jpg"
import sibi from "../Images/aboutus/Group 1572.jpg"
import advi from "../Images/aboutus/two-content-business-partners-discussing-issue.jpg"
import reser from "../Images/aboutus/you-think-there-was-lie-polygraph-examiners-works-office-with-his-equipment.jpg"
import linked from "../Images/icons/linked.png";
import 'aos/dist/aos.css';



export const Twocard = () => {
  return (
    <>
    
    {/* <div className=' items-center grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 grid-cols-1  xl:px-36 lg:px-36 md:px-16 sm:px-16 px-5 py-32 '>
    <ul className="cards gap-20">
   
    <li className=' '>
    <a href="" className="card">
    <img src={advi} className="card__image" alt="" />
   
      <div className="card__overlay">
        <div className="card__header ">
          <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img className="card__thumb" src={bhaskar} alt="" />
          <div className="card__header-text">
            <h3 className="text-sm xl:text-2xl  lg:text-lg md:text-base sm:text-base font-bold "><span class="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">Bhaskar Vadlamani</span></h3>            
            <span className="card__status font-semibold text-white ">Advisor</span>
            <a href="https://www.linkedin.com/in/bhaskar-vadlamani-698b94a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app " target="_blank"><img className="xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 mt-2 h-6" src={linked} alt="" /></a>
          </div>
        </div>
        <p className="text-base p-5 font-semibold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?</p>
      </div>
      
    </a>      
  </li>
 



  <li  className=' ' >
    <a href="" className="card">
    <img src={reser} className="card__image" alt="" />
      
      <div className="card__overlay">        
        <div className="card__header">
          <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                 
          <img className="card__thumb" src={sibi} alt="" />
          <div className="card__header-text">
            <h3 className="text-sm xl:text-2xl  lg:text-lg md:text-base sm:text-base font-bold "><span className="bg-clip-text font-sans font-bold  text-transparent gradient-text-green">Sibi Chakkaravarthy Sethuraman</span></h3>
            <span className="card__status font-semibold text-white">Advisor & Security Researcher</span>
            <a href="https://www.linkedin.com/in/sibi-chakkaravarthy-sethuraman-7aba651a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank"><img className="xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 mt-2 h-6" src={linked} alt="" /></a>
          </div>
        </div>
        <p className="text-base p-5 font-semibold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?</p>
      </div>
      
    </a>
  </li>
    

  
</ul>
</div> */}

<div className="bg-vector1 bg-cover bg-[#081226] xl:py-16 lg:py-12 md:py-10 sm:py-10 py-9 ">
        <h3 className=" text-xl xl:text-4xl  lg:text-4xl md:text-3xl sm:text-2xl font-sans font-bold text-center text-[#79C942]">
          ADVISOR
        </h3>
      </div>

<div  className='  bg-[#081226] xl:py-16 lg:py-12 md:py-12 sm:py-8 py-6 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-16 xl:px-32 lg:px-24 md:px-14 sm:px-28 px-12 '>
<div data-aos="zoom-in-down" data-aos-delay="300"     data-aos-duration="500" className="  bg-[#595B57] bg-opacity-40 shadow-lg rounded-lg overflow-hidden">
    <img className="w-full h-48 md:h-64 lg:h-56 xl:h-64 object-cover" src={bhaskar} alt="Card Image"/>
    <div className="p-6">
        <h3><span className="bg-clip-text font-sans font-bold text-transparent gradient-text-green text-2xl">Bhaskar Vadlamani</span></h3>
        <h4 className="font-semibold text-white mt-3">Advisor</h4>
        <a href="https://www.linkedin.com/in/bhaskar-vadlamani-698b94a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank">
            <img class="mt-3 h-6" src={linked} alt="" />
        </a>
    </div>
</div>

<div data-aos="zoom-in-down" data-aos-delay="300"     data-aos-duration="500"  className="  bg-[#595B57] bg-opacity-40 shadow-lg rounded-lg overflow-hidden">
    <img className="w-full h-48 md:h-64 lg:h-56 xl:h-64 object-cover" src={sibi} alt="Card Image"/>
    <div className="p-6">
        <h3><span className="bg-clip-text font-sans font-bold text-transparent gradient-text-green text-2xl">Sibi Chakkaravarthy Sethuraman</span></h3>
        <h4 className="font-semibold text-white mt-3">Advisor & Security Researcher</h4>
        <a href="https://www.linkedin.com/in/sibi-chakkaravarthy-sethuraman-7aba651a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank"><img className="xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 mt-2 h-6" src={linked} alt="" /></a>
    </div>
</div>

  </div>
    </>
  )
}
